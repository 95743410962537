export function THead(props) {
    return (
        <thead>
        <tr>
            <th>URL</th>
            <th>CODE</th>
            <th>TITLE</th>
            <th>DESCRIPTION</th>
            <th>H1</th>
        </tr>
        </thead>
    )
}